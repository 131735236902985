import React from 'react'
import styled from 'styled-components'
import Card from './card'
import { Wrapper } from './baseStyles'
import guest from '../images/briga-o-gostima.svg'
import channels from '../images/na-kanalima.svg'
import contract from '../images/ugovor.svg'
import business from '../images/poslovanje.svg'
import single from '../images/individualan.svg'
import payment from '../images/naplata.svg'
import H2 from './h2'
import { FormattedMessage } from 'react-intl'
import CustomButton from '../components/button'
import LocalisedLink from '../components/localisedLink'


const Title = styled(H2)`
  text-align: center;
  padding-bottom: 50px;
  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }
`
const ExtendedWrapper = styled(Wrapper)`
  padding:80px 15px;
  @media screen and (max-width:768px){
    padding:50px 15px 20px 15px;
  }
`
const cardsHr = [
	{
		image: channels,
		title: 'Turizam je naša specijalnost',
		paragraph:
			'Pratimo najnovije turističke trendove i prilagođavamo ih potrebama naših partnera.',
	},
	{
		image: guest,
		title: 'Brinemo o tvojim gostima',
		paragraph: 'Više se ne moraš misliti o turistima koji su se izgubili "tamo negdje oko Plitvica".',
	},
	{
		image: single,
		title: 'Oglašavamo na više od 200 kanala',
		paragraph: 'Promoviramo smještaj na svim vodećim platformama s ciljem povećanja broja rezervacija.',
	},
	{
		image: payment,
		title: 'Obavljamo administrativne poslove ',
		paragraph:
			'Preuzimamo izdavanje računa prema gostima i vodimo godišnju evidenciju prometa.',
	},
	{
		image: contract,
		title: 'Raspoloživi smo za sve tvoje upite',
		paragraph:
			'Obrati se našim stručnjacima za sva pitanja oko nekretnine, iznajmljivanja ili gostiju.',
	},
	{
		image: business,
		title: 'Poslujemo transparentno',
		paragraph:
			'Cijenimo povjerenje naših partnera te im pružamo detaljan pregled rezervacija u svakom trenutku.',
	},
]
const cardsEn = [
	{
		image: channels,
		title: 'Tourism <br/> is our specialty',
		paragraph:
			'We track the latest tourist trends and adapt to the our partners requirements.',
	},
	{
		image: guest,
		title: 'We care about your guests',
		paragraph: 'Stop bothering about guests who just got lost somewhere around Plitvice lakes.',
	},
	{
		image: single,
		title: 'More than 200 promotional channels',
		paragraph: 'Discover how can omnichannel marketing help you get more reservations.',
	},
	{
		image: payment,
		title: 'Forget about the administration work',
		paragraph:
			'Say goodbye to invoices, annual turnover records and other administrative assignments.',
	},
	{
		image: contract,
		title: 'Always <br/> at your disposal',
		paragraph:
			'Get in touch with our team for any questions about your villa, renting process or guests.',
	},
	{
		image: business,
		title: 'Straightforward collaboration',
		paragraph:
			'Our partners are provided with a detailed business overview at any given moment.',
	},
]
const Button = styled.div`
  text-align: center;
  `
const CardListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`
const CardList = ({ locale }) => {
	let cards = cardsHr
	if (locale === 'en') {
		cards = cardsEn
	}
	return (
		<ExtendedWrapper>
			<Title color="blue">
				<FormattedMessage id="cardListTitle" />
			</Title>
			<CardListWrapper>
				{cards.map((card, i) => {
					return <Card
						key={i}
						image={card.image}
						title={card.title}
						paragraph={card.paragraph}
					/>
				})}

			</CardListWrapper>
			<Button>
				<LocalisedLink
					style={{ marginTop: '50px', display: 'block' }}
					to="/vodjenje-apartmana-split"
					title={locale === 'hr' ? 'Vođenje, održavanje i čišćenje apartmana Split | Litto Booking' : 'Property management, advertising and maintenance | Litto Booking'}>
					<CustomButton
						bigger
						bold
						height={'50px'}>
						<FormattedMessage id="servicesBottomButton" /></CustomButton>
				</LocalisedLink>

			</Button>
		</ExtendedWrapper>
	)
}
export default CardList
