import styled, { css } from 'styled-components'
import pickColor from '../utlis/pickColor'

const H4 = styled.h4`
  font-family: ${props => props.title ? "Larsseit-Bold" : "Grotesk-SemiBold"};
  font-size:27px;
  line-height:35px; 
  padding-bottom:${props => props.offset ? props.offset : "30px"};
  margin-top:${props => props.marginTop && props.marginTop};
  color:${props => pickColor(props.color)};
  @media screen and (max-width:768px){
    font-size: 21px;
    line-height: 29px;
    }
    ${props => props.subtitle && css`
    font-family:"Larsseit-Bold";`
  }
`
export default H4;