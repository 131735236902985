import React from 'react'
import styled from 'styled-components'
import Paragraph from './paragraph'
import H3 from './h3'

const CardWrapper = styled.div`
  width:calc((100% / 3) - 60px);
  text-align:center;
  margin-bottom:20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    width:calc((100% / 2) - 70px);
  }
  @media screen and (max-width: 560px) {
    width:80%;
    margin:0 auto 20px auto;
  }
`
const Title = styled(H3)`
  font-size: 26px;
  padding-bottom:10px;
  min-height:80px;
  width: 89%;
  @media screen and (max-width: 768px) {
    min-height:20px;
  }
`

const Img = styled.img`
  height:180px;
  margin-bottom:10px;
`
const Card = ({ image, title, paragraph }) => {
	return (
		<>
			<CardWrapper>
				<Img src={image} alt={image} />
				<Title color="blue">
					<span
						dangerouslySetInnerHTML={{ __html: title }}
					/>
				</Title>
				<Paragraph>
					<span
						dangerouslySetInnerHTML={{ __html: paragraph }}
					/>
				</Paragraph>
			</CardWrapper>
		</>
	)
}
export default Card;