import React, { useState, useEffect } from 'react'
import PromoBox from '../components/promoBox'
import CardList from '../components/CardList'
import CtaBanner from '../components/ctaBanner'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FormModal from '../components/formModal'
import { withPrefix, graphql } from 'gatsby'


const IndexPage = ({ location, pageContext: { locale }, data }) => {
  const isHomePage =
    location.pathname === withPrefix('/') ||
    location.pathname === withPrefix('/en') ||
    location.pathname === withPrefix('/en/')


  return (
    <Layout
      fluid={data.file.childImageSharp.fluid}
      isHomePage={isHomePage}
      locale={locale}
    >
			<SEO
				title={
					locale === 'hr'
						? 'Naslovna | Želiš pune apartmane u sezoni? | Litto za Partnere'
						: 'Home | Looking to maximize your rental revenue? | Litto For Partners'
				}
				description={
					locale === 'hr'
						? 'Bliži se nova turistička sezona, a ti ne želiš gubiti vrijeme na turiste? Postani partner i iskoristi sljedeću sezonu za obitelj, prijatelje i odlazak na plažu.'
						: 'The summer season is upon us and you don’t want to waste any time on tourists? Let’s partner up so you can spend your holidays with family and friends - on the beach, preferably.'
				}
				keywords={['Litto']}
				lang={locale}
			/>
      <PromoBox locale={locale} />
      <CardList locale={locale} />
			<CtaBanner locale={locale}/>
    </Layout>
  )
}
export const query = graphql`
  {
    file(relativePath: { regex: "/homepage_header.jpg/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
export default IndexPage
