import React from 'react';
import styled from 'styled-components';
import { Wrapper } from './baseStyles'
import Paragraph from './paragraph'
import H2 from './h2'
import H3 from './h3'
import H4 from './h4'
import iconOne from '../images/icon01.svg'
import iconTwo from '../images/icon02.svg'
import iconThree from '../images/icon03.svg'
import { blue } from '../utlis/colors';
import { FormattedMessage } from 'react-intl'
import LocalisedLink from '../components/localisedLink'
import CustomButton from './button'

const BoxWrapper = styled(Wrapper)`
  background-color:${blue};
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:60px 0 80px 0;
  margin-top:-140px;
  position:relative;
  z-index:4;
  @media screen and (max-width:1180px){
    width: 90%;
  }
  @media screen and (max-width:768px){
    margin:-120px auto 0 auto;
    width: calc(100% - 30px);
    padding:40px 0 40px 0;
  }
`

const ListWrapper = styled.div`
  display:flex;
  width: calc((100% / 12) * 11);
  margin: 0 auto;
  margin-bottom: 55px;
  
  &:last-child{
    margin-bottom:0;
  }
  justify-content:${props => props.right && "flex-end"};
  @media screen and (max-width:768px){
    width: 100%;
    position: relative;

  }
  @media screen and (max-width: 500px){
    white-space: pre-line;
  }
`

const Image = styled.div`
  max-width: 130px;
  max-height: 130px;
  margin-right:${props => !props.right && "20px"};
  margin-left:${props => props.right && "20px"};
  order:${props => props.right && "2"};
  @media screen and (max-width:768px){
    position:absolute;
    right:${props => props.right && "-40px"};
    left:${props => !props.right && "-40px"};
    margin-right:${props => !props.right && "0px"};
    margin-left:${props => props.right && "0px"};
    max-width: 120px;
    max-height: 120px;
  }
`

const ListWrapperTitle = styled(H3)`
  text-align:${props => props.right ? "right" : "left"};
`

const ListWrapperSubtitle = styled(Paragraph)`
  text-align:${props => props.right ? "right" : "left"};
  @media screen and (max-width:768px){
    font-size:17px;
    line-height:25px;
  }
`

const ListWrapperInfo = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    max-width: calc(100% - 210px);
    @media screen and (max-width:768px){
      max-width: 385px;
      margin-left:${props => props.right && "100px"};
      margin-right:${props => !props.right && "100px"};
    }
`
const HelperWrapper = styled.div`
  width: calc((100% / 12) * 10);
  @media screen and (max-width:960px){
    width: calc((100% / 12) * 11);
  }
  @media screen and (max-width:768px){
    width: 100%;
    padding:0 25px;
  }
`

const Icon = styled.img`
  width:100%;
`

const PromoBox = ({locale}) => {
  return (
    <BoxWrapper>
      <HelperWrapper>
        <H2 color="yellow"><FormattedMessage id="promoBoxHeaderTitle" /></H2>
        <H4 color="white">
          <FormattedMessage id="promoBoxHeaderText" />
        </H4>
        <div style={{marginTop: "30px"}}>
          <ListWrapper>
            <Image>
              <Icon src={iconThree} alt='icon-one' />
            </Image>
            <ListWrapperInfo right={true}>
              <ListWrapperTitle color="yellow"> <FormattedMessage id="promoBoxService1Title" /></ListWrapperTitle>
              <ListWrapperSubtitle color="white"><FormattedMessage id="promoBoxService1Text" /></ListWrapperSubtitle>
            </ListWrapperInfo>
          </ListWrapper>

          <ListWrapper right={true}>
            <Image right={true}>
              <Icon src={iconTwo} alt='icon-two' />
            </Image>
            <ListWrapperInfo>
              <ListWrapperTitle color="yellow" right={true}>
                <FormattedMessage id="promoBoxService2Title" />
              </ListWrapperTitle>
              <ListWrapperSubtitle color="white" right={true}>
                <FormattedMessage id="promoBoxService2Text" />
              </ListWrapperSubtitle>
            </ListWrapperInfo>
          </ListWrapper>

          <ListWrapper>
            <Image>
              <Icon src={iconOne} alt='icon-three' />
            </Image>
            <ListWrapperInfo right={true}>
              <ListWrapperTitle color="yellow">
                <FormattedMessage id="promoBoxService3Title" />
              </ListWrapperTitle>
              <ListWrapperSubtitle color="white">
                <FormattedMessage id="promoBoxService3Text" />
              </ListWrapperSubtitle>
            </ListWrapperInfo>
          </ListWrapper>

        </div>
				<LocalisedLink
          style={{ textDecoration: 'none', marginTop: "50px", display: 'block' }}
          to="/dogovori-sastanak"
          title={
            locale === 'hr'
              ? 'Dogovori sastanak| Litto Booking'
              : 'Arrange meeting | Litto Booking'
          }
        >
          <CustomButton bigger yellow black bold mobileCenter height="60px" >
            <FormattedMessage id="becomeAPartner" />
          </CustomButton>
        </LocalisedLink>
      </HelperWrapper>
    </BoxWrapper>
  )
}

export default PromoBox;