import React from 'react'
import { Wrapper } from './baseStyles'
import { yellow, blue } from '../utlis/colors';
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import illustrationWaving from '../images/illustration_waving.svg'
import LocalisedLink from '../components/localisedLink'
import CustomButton from '../components/button'

const StyledWrapper = styled(Wrapper)`
	margin-top: 80px;
	margin-bottom: 160px;
	@media screen and (min-width: 768px) {
		margin-bottom: 120px;
	}
`;

const CtaBannerWrap = styled.div`
  display: flex;
  background: ${yellow};
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
	padding: 80px 40px 0;
	flex-direction: column-reverse;	
	
	@media screen and (min-width: 768px) {
		padding: 30px 100px;
		min-height: 400px;
		flex-direction: row;
	}

  div {
		@media screen and (min-width: 768px) {
    	max-width: 50%;
		}
    > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  img {
    margin-top: 95px;
    margin-bottom: -90px;
  }

  h2 {
		font-family: Larsseit-Bold;
		/* font-weight: bold; */
		color: ${blue};
    font-size: 34px;
    max-width: 380px;
  }

	p{
		font-size: 16px;
		line-height: 1.5;
		@media screen and (min-width: 768px) {
			font-size: 18px;
		}
	}
`;

const CtaBanner = ({ locale }) => {
	return (
		<StyledWrapper>
			<CtaBannerWrap>
				<img alt="offer" src={illustrationWaving} />
				<div>
					<h2><FormattedMessage id="ctaBannerTitle" /></h2>
					<p>
						<FormattedMessage id="ctaBannerText" />
					</p>

					<LocalisedLink
						style={{ display: 'block' }}
						to="/vodjenje-apartmana-split"
						title={locale === 'hr' ? 'Vođenje, održavanje i čišćenje apartmana Split | Litto Booking' : 'Property management, advertising and maintenance | Litto Booking'}>
						<CustomButton
							bigger
							bold
							height={'50px'}>
							<FormattedMessage id="servicesBottomButton" /></CustomButton>
					</LocalisedLink>

				</div>
			</CtaBannerWrap>
		</StyledWrapper>
	);
};

export default CtaBanner;